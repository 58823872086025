import React, { useEffect, useState, useMemo } from 'react';
import './style.scss';
import {useForm} from "react-hook-form";
import {useQueryParam, NumberParam, withDefault} from "use-query-params";
import FormButton from '../../generic/buttons/main';
import { usePublicEndpoints } from "../../../lib/api/usePublicEndpoints";
import PropTypes from 'prop-types';
import _ from "lodash";
import {isFieldEmpty} from "../../../lib/utils/helpers/formsHelpers";
import {localizeDate} from "../../../lib/utils/helpers";
import {parseErrors, RESPONSE_ERROR_MESSAGES} from "../../../lib/utils/errors";
import {useLoaderState} from "../../../lib/api/loaderState";
import {t} from "i18next";
import {useLocation} from "react-router-dom";
import {usePaymentsApi} from "../../../lib/api/usePaymentsApi";
import {convertToISO} from "../../../lib/utils/format";
import { useEventRecordsApi } from '../../../lib/api/eventRecords';
import { eventTypes } from 'sv-common/constants/externalEvents';
import { getCookie } from '../../../lib/utils/cookies';

const prepareUser = (user) => ({
    email: user?.email || '',
    phone: user?.phone || '',
    last_name: user?.last_name  || '',
    first_name: user?.first_name || '',
    birthdate: user?.birthdate ? localizeDate(user.birthdate) : '',
    sailing_experience: user?.sailing_experience || '',
    gender: user?.gender || '',
    is_insurance: user?.is_insurance,
})

const MembersScreen = ({ finalize, eventData, step, order, payingUser, setPayingUser, tablet = false, renderPeopleForms, renderCheckboxes }) => {
  const { record } = useEventRecordsApi();
  const [peopleAmount] = useQueryParam("people", withDefault(NumberParam, 1));
  const [serverError, setServerError] = useState('');
  const [dataContainer, setDataContainer] = useState(null);
  const isInsuredBefore = useMemo(() => !_.isNull(payingUser?.is_insurance), []);

  const { createOrder } = usePublicEndpoints();
  const { createPayment } = usePaymentsApi();
  const { pathname } = useLocation();

  const form = useForm({ mode: "onChange", reValidateMode: 'onChange'});
  const { register, errors, handleSubmit, getValues, setError, reset, watch } = form;

  const {setIsLoading, isLoading} = useLoaderState();

  const showSingleForm = watch("duplicateData")
  const ageConsent = tablet ? true : watch("ageConsent")

  const onCreate = ({peopleData}) => {
      let participantsList;
      let participants;
      if (tablet) {
          participantsList = peopleData
          participants = participantsList
      } else {
          participantsList =  peopleData.map(person => {
              const birthdate = convertToISO(person.birthdate)
              return {
                  ...person,
                  birthdate
              }});

          participants = participantsList.length !== peopleAmount
              ? new Array(peopleAmount).fill(participantsList[0])
              : participantsList
      }

    // чтобы информация обновилась на втором экране, нужно обновить payingUser
      if (!tablet) setPayingUser(u => ({...u, ...participantsList[0]}))

    // добавляем поле is_insured к первому попутчику, если чекбокс страховки активирован
      if (getValues('is_insured') && participants.length > 0) {
        participants[0] = { ...participants[0], is_insured: true };
      }

    let data = {
      participants,
      product: "slots",
      productId: dataContainer.slot.id,
      payment_currency: dataContainer.priceCurrency,
      eventData: eventData || null,
      full_book_discount_applied: !!dataContainer?.full_book_discount?.active,
      coachless_discount_applied: !!dataContainer?.coachless_discount?.active,
      own_boat_discount_applied: !!dataContainer?.own_boat_discount?.active,
      ym_client_id: getCookie(`_ym_uid`),
      is_insured: getValues('is_insured'),
    };
    if (dataContainer.instructor) data.instructor = dataContainer.instructor
    if (order?.id) data.step = step;

    if(!isFieldEmpty('phone', data.participants, setError)) {
        setIsLoading(true);
        createOrder(data)
          .then(res => {
              if (tablet && res.status === 'created' && res.orderId) {
                  createPayment(res.orderId, pathname + `?orderId=${res.orderId}&step=2`)
                      .catch(e => {
                          console.log(e)
                      })
                      .finally(() => {
                          setIsLoading(false);
                      })
              }
            finalize(peopleData, res.orderId)
          })
          .catch(e => {
              const eMessage = e.response?.data?.message;
              const error = parseErrors(eMessage);
              if (error === RESPONSE_ERROR_MESSAGES.PHONE_TAKEN){
                  const participantId = participantsList.findIndex(p => p.phone === eMessage.split(': ')[1]);
                  setError(`peopleData.${participantId}.phone`, {type: 'custom', message: t('errors.phoneAlreadyUses')})
              }
              else setServerError(t(parseErrors(e.response?.data?.message)))
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }

  useEffect(() => {
      let peopleData;

      if (tablet) {
          peopleData = new Array(peopleAmount).fill({})
      } else if (showSingleForm) {
          peopleData = [payingUser]
      } else if (order?.participants?.length === peopleAmount) {
          peopleData = order?.participants
      } else {
         peopleData = [
             payingUser,
             ...new Array(peopleAmount - 1).fill({})
         ]
      }

      const duplicateData = tablet ? false : showSingleForm

      reset({peopleData: peopleData?.map(prepareUser), duplicateData, ageConsent})
  }, [payingUser, showSingleForm, order]);

  useEffect(() => {
    setDataContainer(() => _.isEmpty(eventData) ? order : eventData);
  }, [eventData, order]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    eventData?.eventId && record(eventTypes.ON_STEP_2_EVENT, eventData);
  }, []);

  const values = getValues();

  return (
    <form className={'members-form'}
      onSubmit={handleSubmit(onCreate)}
    >
      {renderPeopleForms({form, payingUser})}
      {renderCheckboxes({isInsuredBefore, dataContainer, register, errors, values, peopleAmount})}
      {!!serverError &&
          <p className="member">
              {serverError}
          </p>
      }
      <FormButton type="submit" value={t('common.next')} disabled={isLoading || !!serverError} />
    </form>
  )
}

MembersScreen.propTypes = {
  step: PropTypes.number,
  order: PropTypes.object,
  finalize: PropTypes.func,
  eventData: PropTypes.object,
  payingUser: PropTypes.object,
}

export default MembersScreen;